import {Component, ElementRef, Input, OnInit, OnDestroy, ViewChild} from '@angular/core';
import * as _ from 'lodash';

import { UtilFns } from '../../utils/common';
import {HttpClient, HttpHeaders, HTTP_INTERCEPTORS} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Reference, RIFMDocument, SurveyWhoUsedMostMaterial} from '../../entities/EntityModels';
import {PDFEditorComponent} from '../docmanagement/pdf-editor.component';
import {UnitOfWork} from '../../services/unit-of-work';
import {ModalDialog} from '../../controls/modal-dialog';
import {StateMap} from '../../services/state-map';


@Component({
    selector: 'show-and-download-pdf',
    templateUrl: './show-and-download-pdf.html',
})
export class ShowAndDownloadPDFComponent extends ModalDialog<SurveyWhoUsedMostMaterial> implements OnInit, OnDestroy {
    @ViewChild(PDFEditorComponent, { static: true }) _pdfEditorComponent: PDFEditorComponent;

    @Input() showUVSFile: boolean;

    public _fileName = '';
    public _isLoading = false;
    public _validationMessage = '';

    public _pdfEditorDisplay = 'inline';
    public _pdfEditorMessage = '';

    public _documentManagementUrl: string;

    constructor(elementRef: ElementRef, public _uow: UnitOfWork, private _httpClient: HttpClient, public _stateMap: StateMap) {
        super(elementRef);
    }

    ngOnInit() {
        // documentManagementBaseUrl is set in the home page component as a workaround for the Syncfusion pdf viewer
        this._documentManagementUrl = (this.showUVSFile) ? this._stateMap.documentManagementBaseUrl + '/AnalyticalResults' : this._stateMap.documentManagementBaseUrl + '/RIFMDocument';
        this._stateMap.documentManagementUrl = this._documentManagementUrl;
    }

    ngOnDestroy() {
        if (this._pdfEditorComponent != null) {
            this._pdfEditorComponent.destroyData();
        }

        // remove application cache
        this.clearPDFViewerCache();
    }

    public clearPDFViewerCache() {
        // remove application cache
        for (const key in sessionStorage) {
            if (key.indexOf('Sync_PdfViewer') !== -1) {
                sessionStorage.removeItem(key);
            }
        }
    }

    get utilFns() {
        return UtilFns;
    }

    public get fileName(): string {
        return this._fileName;
    }

    public set fileName(fileName: string) {
        this._fileName = fileName;
        this.loadPDFFileData(this._fileName);
    }

    // ************************************************************************
    // PDF Component Interaction
    // ************************************************************************
    public onExportFile() {
        this.exportPDFFile(this._fileName);
    }

    public loadPDFFileData(fileName: string) {
        this._isLoading = true;
        this._validationMessage = 'Loading PDF file...';

        this._pdfEditorComponent.canShowPDF = true;
        this._pdfEditorComponent.loadPDF(fileName);
        this._pdfEditorComponent.setViewMode();
    }

    pdfEditorEventHandler(event: string) {
        switch (event) {
            case 'documentloaded': {
                break;
            }
            case 'success': {
                // this._pdfEditorMessage = this._pdfEditorLastRequest + ' Result: ' + 'Success.';
                break;
            }
            case 'error': {
                this._pdfEditorMessage = 'Error occurred, please contact support.';
                break;
            }
            default: {
                break;
            }
        }
        this._isLoading         = false;
        this._validationMessage = '';
    }

    // ************************************************************************
    // document management web api url
    // ************************************************************************
    public getDocumentManagementUrl(): Promise<any> {
        return this._uow.fetch('DocumentManagement/DocumentManagementURL', {}).then(dm => {
            return dm[0];
        });
    }

    public isValidString(testString: string): boolean {
        return (testString && testString.length > 0);
    }

    // ************************************************************************
    // Export PDF File
    // ************************************************************************
    getExportFileURL(fileName: string): Observable<any> {
        let url;

        if (this.showUVSFile) {
            url = this._documentManagementUrl + '/GetUVFileByName?fileName=' + fileName;
        } else {
            url = this._documentManagementUrl + '/GetReferenceDocumentPDFByFileName?fileName=' + fileName;
        }

        console.log(url);

        return this._httpClient.get<any>(url, { responseType: 'blob' as 'json'});
    }

    public exportPDFFile(fileName: string) {
        this._isLoading = true;
        this._validationMessage = '';
        this.getExportFileURL(fileName)
            .subscribe(
                (response: Blob) => {
                    const data = response;

                    const file = new Blob([data], { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);

                    // window.open(fileURL);
                    const a         = document.createElement('a');
                    a.href        = fileURL;
                    a.target      = '_blank';
                    a.download    = fileName;
                    document.body.appendChild(a);
                    a.click();

                    this._validationMessage = 'File download successful.';

                },
                (error) => {
                    console.error('Request failed with error: ' + error.message);
                    this._validationMessage = error.message;
                    this._isLoading = false;
                },
                () => {
                    this._isLoading = false;
                });
    }
}
